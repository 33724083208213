import React, { useMemo, useCallback, useContext } from "react";

import {
  updateColumnById,
  deleteColumnById

} from "./listUtils";
import useLocalStorage from "../../../../hooks/useLocalStorage";
import { Column } from "./Column/types";
import { ModuleData } from "src/core/reducers/designer/designerEntities";


interface Context {
  columns: Column[];
  setColumns: React.Dispatch<React.SetStateAction<Column[]>>;
  addColumn: (module:ModuleData) => void;
  updateColumn: (module:ModuleData) => void;
  deleteColumn: (id: string) => void;

}

const BoardContext = React.createContext<Context | undefined>(undefined);

const BoardProvider: React.FC = ({ children }) => {
  const [columns, setColumns] = useLocalStorage<Column[]>("board", []);

  const addColumn = useCallback(
    (module:ModuleData) => {
      setColumns([...columns, { module }]);
    },
    [setColumns, columns]
  );

  const updateColumn = useCallback(
    (module:ModuleData) => {
      setColumns(updateColumnById(columns, { module }));
    },
    [setColumns, columns]
  );

  const deleteColumn = useCallback(
    (id: string) => {
      setColumns(deleteColumnById(columns, id));
    },
    [setColumns, columns]
  );



  const defaultValue = useMemo(
    () => ({
      columns,
      setColumns,
      addColumn,
      updateColumn,
      deleteColumn,

    }),
    [
      columns,
      setColumns,
      addColumn,
      updateColumn,
      deleteColumn,

    ]
  );
  return (
    <BoardContext.Provider value={defaultValue}>
      {children}
    </BoardContext.Provider>
  );
};

function useBoard() {
  const context = useContext(BoardContext);
  if (context === undefined) {
    throw new Error(`useBoard must be used within a BoardProvider`);
  }
  return context;
}

export { BoardProvider, useBoard };
