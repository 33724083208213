import { Navigate, Outlet } from 'react-router-dom';
import { LoginStatus } from '../../core/reducers/user/enums';
interface ProtectedRouteProps {
    loginStatus: LoginStatus;
    redirectPath: string;
}
const ProtectedRoute = ({ loginStatus, redirectPath }: ProtectedRouteProps) => {
    // Think if we want useEffect on loginStatus dependency for track on login status changes...
    if (LoginStatus.SUCCESS !== loginStatus && LoginStatus.LOADING !== loginStatus) {
        return <Navigate to={redirectPath} replace />;
    }
    return <Outlet />;
};
export default ProtectedRoute;