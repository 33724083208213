import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';


import 'nprogress/nprogress.css';
import App from 'src/App';
import { Provider } from 'react-redux';
import './i18n';
import { store } from './core/store';

import { SidebarProvider } from 'src/contexts/SidebarContext';
import * as serviceWorker from 'src/serviceWorker';
import { BoardProvider } from './content/dashboards/Designer/Components/boardContext';
ReactDOM.render(
  <HelmetProvider>
    <SidebarProvider>
      
      <BoardProvider>
      <Provider store={store}>
        <App />
        </Provider>
        </BoardProvider>,
      
    </SidebarProvider>
  </HelmetProvider>,
  document.getElementById('root')
);

serviceWorker.unregister();
