
import { UserDetails } from "../reducers/user/userEntities";

export class UserService {
    public SignUpEmail: string;
    
    public Reset():void {
        this.sessionData=null;
        this.sessionId=null;
        this.cSRF=null;
    }

    private sessionData: string;

    private sessionId: string;
    
    private cSRF: string;


    public get SessionData(): string {
        return this.sessionData;
    }
    
    public get SessionId(): string {
        return this.sessionId;
    }

    public get CSRF(): string {
        return this.cSRF;
    }

    public initUser(value: UserDetails): void {
        this.sessionData = value.SessionData;
        this.sessionId = (value.SessionId);
        this.cSRF = (value.GeneratedToken)
       
    }

}