
import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'src/core/store';
import { createApiThunk } from '../../api/apiService';
import { SaveUserLayoutsData, GetMetaDataData, DesignerDataState, GetUserLayoutsData, DeleteUserLayoutsData } from './designerEntities';

export const fetchMetaData = createApiThunk<GetMetaDataData>('designer/getMetaData', 'GetMetaData');
 export const fetchUserLayouts = createApiThunk<GetUserLayoutsData>('designer/getUserLayouts', 'GetUserLayouts');
export const saveUserLayouts = createApiThunk<SaveUserLayoutsData>('designer/saveUserDesignerData', 'SaveUserLayouts');
export const deleteUserLayouts = createApiThunk<DeleteUserLayoutsData>('designer/deleteUserLayout', 'DeleteUserLayout');


const initialState: DesignerDataState =
{
    isMetaDataLoading: true,
    isMetaDataLoaded: false,
    metaData: {},

    userLayout:{},
    isUserLayoutLoaded: false,
    isUserLayoutLoading: false,

    isSavesCompleted: false,
    isDeleteCompleted:false

};
export const metaDataLoaded = (state: RootState) => state.designer.isMetaDataLoaded;
export const designerDataState = (state: RootState) => state.designer.metaData;

export const userLayoutLoaded = (state: RootState) => state.designer.isUserLayoutLoaded;
export const userLayout = (state: RootState) => state.designer.userLayout;

export const savesCompleted = (state: RootState) => state.designer.isSavesCompleted;
export const deleteCompleted = (state: RootState) => state.designer.isDeleteCompleted;



export const DesignerSlice = createSlice({
    name: 'designer',
    initialState,
    reducers: {
        clearDesignerData: state => {
            state.metaData = null;

        },
        updateContextKeys: (state, payload) => {

        },
        clearFields: state => {

        },
        clearLayout: state => {

        }
    },
    extraReducers: builder => {
        builder

            .addCase(fetchMetaData.pending, state => {
                state.isMetaDataLoading = true;
            })
            .addCase(fetchMetaData.fulfilled, (state, action) => {

                if (action.payload.Status === 'Fail') {
                    state.isMetaDataLoaded = false;
                }
                else if (action.payload.Status === 'Success') {
                    state.metaData = action.payload.ResultData;
                    state.isMetaDataLoaded = true;
                    state.isMetaDataLoading = false;
                }
            })

            .addCase(fetchUserLayouts.pending, state => {
                state.isUserLayoutLoading = true;
            })
            .addCase(fetchUserLayouts.fulfilled, (state, action) => {

                if (action.payload.Status === 'Fail') {
                    state.isUserLayoutLoaded = false;
                }
                else if (action.payload.Status === 'Success') {
                    if(action.payload.ResultData){
                        state.userLayout = action.payload.ResultData;
                    }
                   
                    state.isUserLayoutLoaded = true;
                    state.isUserLayoutLoading = false;
                }
            })



            .addCase(saveUserLayouts.pending, state => {
                state.isSavesCompleted = false;
            })
            .addCase(saveUserLayouts.fulfilled, (state, action) => {
                state.isSavesCompleted = true;
            })

            .addCase(saveUserLayouts.rejected, state => {

            })

            .addCase(deleteUserLayouts.pending, state => {
                state.isDeleteCompleted = false;
            })
            .addCase(deleteUserLayouts.fulfilled, (state, action) => {
                state.isDeleteCompleted = true;
            })

            .addCase(deleteUserLayouts.rejected, state => {
               
            });
    }
});

export default DesignerSlice.reducer;
export const { clearDesignerData: clearDesignerData, updateContextKeys, clearFields, clearLayout } = DesignerSlice.actions;

