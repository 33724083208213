import { configureStore } from '@reduxjs/toolkit';
import userReducer from './reducers/user/userSlice';
 import designerReducer from './reducers/designer/designerSlice';
// import procedureListReducer from './reducers/procedure/procedureListSlice';
// import lookupsReducer from './reducers/metadata/lookups/lookupSlice';
/*
    This is the state store for the entire app
    if we want to make use of future features, we just need to add them to the reducer list below
*/
export const store = configureStore({
  reducer: {
    user: userReducer,
    designer: designerReducer,
    procedureList: null,
    lookups: null
  }
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;