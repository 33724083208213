export enum LoginStatus {
    NONE = 'None',
    LOADING = 'Loading',
    SUCCESS = 'Success',
    ERROR = 'Error',
    NOT_FOUND = 'NotFound'
}
export enum SignUpStatus {
    NONE = 'None',
    LOADING = 'Loading',
    SUCCESS = 'Success',
    ERROR = 'Error',
  
}


export enum SourceDate {
    TODAY = 'today',
    NOW = 'now',
    SPECIFIC_DATE = 'specific date'
}

export enum BusinessRuleAction {
    SHOW = 'Show',
    ENABLE = 'Enable'
}

export enum ConditionOperator {
    AND = 'And',
    OR = 'Or'
}

export enum TableActionType {
    ADD = 'Add',
    DELETE = 'Remove',
    DELETE_ALL = 'RemoveAll',
    EDIT = 'Update'
}
