import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';

const appVersion = process.env.REACT_APP_VERSION ?? '0';

const isDev = process.env.ENVIRONMENT === 'development';

i18n.use(Backend).use(initReactI18next).init({
    fallbackLng: 'en',
    backend:{
        queryStringParams: { v : appVersion }
    },
    debug : isDev,
    interpolation:{
        escapeValue: false
    }
});

export default i18n;