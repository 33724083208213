// A common utility that contains functions for broad and general usage

import { ConditionOperator } from '../reducers/user/enums';

// export const isDevelopmentMode = () => {
//     return process.env.NODE_ENV === 'development';
// };

export const getBaseApiUrl = () => {
    // if (process)
    //     return process.env.REACT_APP_BASE_API_URL;
    return '/json/reply/';
};

export const getCookie = (cookieName: string) => {
    const name = cookieName + '=';
    const cDecoded = decodeURIComponent(document.cookie);
    const cArr = cDecoded.split('; ');
    let res;
    cArr.forEach(val => {
        if (val.indexOf(name) === 0) res = val.substring(name.length);
    });
    return res;
};

export const sleep = (ms: number) => {
    return new Promise(resolve => setTimeout(resolve, ms));
};

export const getChildrenFields = (parentInternalCode: string, map: Map<string, string[]>): string[] => {
    if (!map.has(parentInternalCode)) {
        return [];
    }

    return map.get(parentInternalCode) as string[];
};

export const isValuesContainValue = (values: string[], value: any): boolean => {
    if (typeof (value) !== 'undefined') {
        if (Array.isArray(value)) {
            return value.some((val: string) => values.includes(val));
        }
        if (typeof value == 'boolean') {
            return values.includes(value.toString());
        }
        return values.includes(value);
    }
    return false;
};

export const getConditionResult = (values: string[], value: any, condition: ConditionOperator | undefined, prevResult: boolean = true)
    : { result: boolean, isFinalResult: boolean } => {

    const curResult = isValuesContainValue(values, value);

    if (typeof (condition) !== 'undefined') {

        switch (condition) {
            case ConditionOperator.OR: {
                return { result: curResult, isFinalResult: curResult };
            }
            case ConditionOperator.AND: {
                if (!curResult || ((prevResult && curResult) === false)) {
                    return { result: false, isFinalResult: true };
                }

                return { result: true, isFinalResult: false };
            }
            default: throw Error(`unrecognized condition operator: ${condition}`);
        }
    }

    return { result: curResult && prevResult, isFinalResult: true };
};