/*
    This is where we gather the actions done on he user state.
    It includes the relevant slice actions and api actions
*/
import {  loginUser, logoutUser, signUpUser, updateTheme } from '../reducers/user/userSlice';
import { UserClientData, UserLoginDetails } from '../reducers/user/userEntities';
import { store } from '../store';
 import { clearDesignerData } from '../reducers/designer/designerSlice';

export const sendLoginDetails = (loginDetails: UserLoginDetails) => {
  store.dispatch(loginUser(loginDetails));
};

export const sendSignUpDetails = (loginDetails: UserLoginDetails) => {
  store.dispatch(signUpUser(loginDetails));
};

export const logoutUserAction = (userData: UserClientData) => {
  // Clear data before loging out from the system
   store.dispatch(clearDesignerData());
  store.dispatch(logoutUser(userData));
};

// export const extendContextUserSession = (userData: UserClientData) => {
//   store.dispatch(extendUserSession(userData));
// };

export const updateNewTheme = (newTheme: string) => {
  store.dispatch(updateTheme(newTheme));
};