
/*
    This service handles the errors received from the api endpoints
*/
export class ErrorService {

    private onUnAuthorizedHandler: () => void = () => { return; };    

    public OnUnAuthorizedReceived(handler: () => void): void {
        this.onUnAuthorizedHandler = handler;
    }

    public HandleReceivedError(endPointUrl: string, statusCode: number): void {
        console.log(`Received ${statusCode} from ${endPointUrl}`);

        switch (statusCode) {
            case 401: {
                this.onUnAuthorizedHandler();
                break;
            }
            case 500: {
                // TODO : handle internal server errors
                break;
            }
        }
    }
}