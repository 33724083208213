

export class SingletonContainer {

    private static singletonInstances: WeakMap<any, any> = new Map<any, any>();

    public static getSingleton<T>(instanceType: { new(): T; }): T {
        if (!this.singletonInstances.has(instanceType)) {
            this.singletonInstances.set(instanceType, new instanceType());
        }

        let singletonInstance: any = this.singletonInstances.get(instanceType);
        return singletonInstance as T;
    }
}