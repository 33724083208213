import { createSlice } from '@reduxjs/toolkit';
import { createApiThunk } from '../../api/apiService';
import { UserClientData, UserLoginState, UserSignupDetails } from './userEntities';
import { LoginStatus, SignUpStatus } from './enums';
import { UserLoginDetails } from './userEntities';
// import Cookies from 'universal-cookie';
// import { isDevelopmentMode } from '../../utils/commUtil';
import { RootState } from '../../store';
import {  useSelector } from 'react-redux';
import { SingletonContainer } from 'src/core/dependencyInjection';
import { UserService } from 'src/core/services/userService';
const initialState: UserLoginState = {
    userData: { Email: '' },
    loginStatus: LoginStatus.NONE,
    signUpStatus: SignUpStatus.NONE,
    theme: 'base'
};


export const loginUser = createApiThunk<UserLoginDetails>('user/loginUser', 'LoginUser');
export const signUpUser = createApiThunk<UserSignupDetails>('user/signUpUser', 'SignUpUser');
export const logoutUser = createApiThunk<UserClientData>('user/logoutUser', 'LogoutUser');


// TEMP
export const getCurrentTheme = (state: RootState) => state.user.theme;
const loginService = SingletonContainer.getSingleton<UserService>(UserService);

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        updateTheme: (state, payload) => {
            state.theme = payload.payload;
        }
    },
    extraReducers: builder => {
        builder
            .addCase(loginUser.pending, state => {
                state.loginStatus = LoginStatus.LOADING;
            })
            .addCase(loginUser.fulfilled, (state, action) => {
                if (action.payload.Status === 'Fail') {
                    state.loginStatus = LoginStatus.NOT_FOUND;
                }
                else if (action.payload.Status === 'Success') {
                    state.loginStatus = LoginStatus.SUCCESS;
                }
                state.userData = action.payload;
                if (state?.userData) {
                    loginService.initUser(state.userData);
                }

            })
            .addCase(loginUser.rejected, state => {
                state.loginStatus = LoginStatus.ERROR;
            })
            .addCase(signUpUser.rejected, state => {
                state.signUpStatus = SignUpStatus.ERROR;
            })
            .addCase(signUpUser.fulfilled, (state, action) => {
                if (action.payload.Status === 'Fail') {
                    state.signUpStatus = SignUpStatus.ERROR;
                }
                else if (action.payload.Status === 'Success') {
                    state.signUpStatus = SignUpStatus.SUCCESS;
                }
                state.userData = action.payload;
                

            })
            
            .addCase(logoutUser.fulfilled, state => {
                state.loginStatus = initialState.loginStatus;
                state.userData = initialState.userData;
                loginService.Reset();
            });
    }
});

export const { updateTheme } = userSlice.actions;
export default userSlice.reducer;