import { BrowserRouter, Route, Routes, useRoutes } from 'react-router-dom';
;

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

import { CssBaseline } from '@mui/material';
import ThemeProvider from './theme/ThemeProvider';
// import ProtectedRoute from './ui/components/ProtectedRoute';

import { lazy, Suspense } from 'react';
import SuspenseLoader from './components/SuspenseLoader/suspenseLoader';
import BaseLayout from './layouts/BaseLayout/baseLayout';
import SidebarLayout from './layouts/SidebarLayout/SidebarLayout';
import { LoginStatus } from './core/reducers/user/enums';
import { RootState } from './core/store';
import { useSelector } from 'react-redux';
import ProtectedRoute from './ui/components/ProtectedRoute';

const Loader = (Component) => (props) =>
(
  <Suspense fallback={<SuspenseLoader />}>
    <Component {...props} />
  </Suspense>
);


function App() {
  // const content = useRoutes(router);

  const loginStatus: LoginStatus = useSelector((state: RootState) => state.user.loginStatus);
  // Pages

  const Home = Loader(lazy(() => import('src/content/home/home')));

  // Dashboards

  const Designer = Loader(lazy(() => import('src/content/dashboards/Designer/designer')));

  // Applications


  const Transactions = Loader(
    lazy(() => import('src/content/applications/Transactions/applicationsTransactions'))
  );

  const UserLayouts = Loader(
    lazy(() => import('src/content/applications/UserLayouts/userLayouts'))
  );
  const UserProfile = Loader(
    lazy(() => import('src/content/applications/Users/profile/managementUserProfile'))
  );
  const UserSettings = Loader(
    lazy(() => import('src/content/applications/Users/settings/managementUserSettings'))
  );

  // Components


  const Buttons = Loader(
    lazy(() => import('src/content/pages/Components/Buttons/buttons'))
  );
  const Modals = Loader(
    lazy(() => import('src/content/pages/Components/Modals/modals'))
  );
  const Accordions = Loader(
    lazy(() => import('src/content/pages/Components/Accordions/accordions'))
  );
  const Tabs = Loader(lazy(() => import('src/content/pages/Components/Tabs/tabs')));
  const Badges = Loader(
    lazy(() => import('src/content/pages/Components/Badges/badges'))
  );
  const Tooltips = Loader(
    lazy(() => import('src/content/pages/Components/Tooltips/tooltips'))
  );
  const Avatars = Loader(
    lazy(() => import('src/content/pages/Components/Avatars/avatars'))
  );
  const Cards = Loader(lazy(() => import('src/content/pages/Components/Cards/cards')));
  const Forms = Loader(lazy(() => import('src/content/pages/Components/Forms/forms')));
  const SignIn = Loader(lazy(() => import('src/content/pages/Components/SignIn/signIn')));
  const SignUp = Loader(lazy(() => import('src/content/pages/Components/SignUp/signUp')));

  // Status

  const Status404 = Loader(
    lazy(() => import('src/content/pages/Status/Status404/status404'))
  );
  const Status500 = Loader(
    lazy(() => import('src/content/pages/Status/Status500/status500'))
  );
  const StatusComingSoon = Loader(
    lazy(() => import('src/content/pages/Status/ComingSoon/comingSoon'))
  );
  const StatusMaintenance = Loader(
    lazy(() => import('src/content/pages/Status/Maintenance/maintenance'))
  );

  return (
    <ThemeProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <CssBaseline />
        <BrowserRouter>
          <Routes>
            <Route path='' element={<BaseLayout />} >
              <Route path='*' element={<Home />} />
              <Route path='/' element={<Home />} />
              <Route path='home' element={<Home />} />

              <Route path='status'  >
                <Route path='' element={<Status404 />} />
                <Route path='*' element={<Status404 />} />
                <Route path='500' element={<Status500 />} />
                <Route path='maintenance' element={<StatusMaintenance />} />
                <Route path='coming-soon' element={<StatusComingSoon />} />
                <Route path='404' element={<Status404 />} />
              </Route>


            </Route>
            <Route element={<ProtectedRoute loginStatus={loginStatus} redirectPath={'/components/signin'} />}>
              {/* <Route path='transactions' element={<Transactions />} /> */}
            </Route>
            <Route path='dashboards' element={<SidebarLayout />} >
              <Route path='' element={<Designer />} />
              <Route path='designer' element={<Designer />} />
            </Route>
            <Route element={<ProtectedRoute loginStatus={loginStatus} redirectPath={'/components/signin'} />}>
            <Route path='management' element={<SidebarLayout />} >
              <Route path='' element={<UserLayouts />} />
              <Route path='userlayouts' element={<UserLayouts />} />
              
              <Route path='transactions' element={<Transactions />} />
              <Route path='profile'>
                <Route path='' element={<UserProfile />} />
                <Route path='details' element={<UserProfile />} />
                <Route path='settings' element={<UserSettings />} />
              </Route>
            </Route>
            </Route>
            <Route path='/components' element={<SidebarLayout />} >
              <Route path='' element={<Buttons />} />
              <Route path='buttons' element={<Buttons />} />
              <Route path='modals' element={<Modals />} />
              <Route path='accordions' element={<Accordions />} />
              <Route path='tabs' element={<Tabs />} />
              <Route path='badges' element={<Badges />} />
              <Route path='tooltips' element={<Tooltips />} />
              <Route path='avatars' element={<Avatars />} />
              <Route path='cards' element={<Cards />} />
              <Route path='forms' element={<Forms />} />
              <Route path='SignIn' element={<SignIn />} />
              <Route path='SignUp' element={<SignUp />} />
              <Route path='cards' element={<Cards />} />
            </Route>


          </Routes>
        </BrowserRouter>
      </LocalizationProvider>
    </ThemeProvider >
  );
}
export default App;
