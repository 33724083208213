import { Column } from "./Column/types";



export function updateColumnById(
  columns: Column[],
  updatedColumn: Omit<Column, "cards">
) {
  return columns.map((column) =>
    column.module.internalId === updatedColumn.module.internalId
      ? { ...column, title: updatedColumn.module.Name }
      : column
  );
}


export function deleteColumnById(columns: Column[], columnId: string) {
  return columns.filter((column) => column.module.internalId !== columnId);
}



export function reorderList<T>(
  list: T[],
  startIndex: number,
  endIndex: number
) {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
}


