import { createAsyncThunk } from '@reduxjs/toolkit';
import { SingletonContainer } from '../dependencyInjection';
import { ErrorService } from '../services/errorService';
// import Cookies from 'universal-cookie';
// import { RootState } from '../store';
import { getBaseApiUrl } from '../utils/commUtil';

import { UserService } from '../services/userService';
// import {  useSelector } from 'react-redux';

const layoutService = SingletonContainer.getSingleton<UserService>(UserService);



const errService = SingletonContainer.getSingleton<ErrorService>(ErrorService);
// const user = useSelector((state: RootState) => state.user.value);
// TEMP solution to get the resource locally
// In the future, this resource will be fetched from the server API
export const createApiDataThunk = (typePrefix: string, path: string, methodName?: string) => {
    methodName = methodName || 'GET';
    return createAsyncThunk(
        typePrefix,
        async (data: any) => {
            const fetchReq: RequestInit = {
                method: methodName,
                mode: 'cors',
                cache: 'no-cache',
                headers: {
                    'Content-Type': 'application/json'
                }
            };

            if (data) {
                fetchReq.body = JSON.stringify(data);
            }

            const response = await fetch(`/resources/${path}`, fetchReq);

            if (response.status === 204) {
                // Empty content - return an empty object
                return {};
            }

            if (response.status === 200) {
                return response.json();
            }

            throw Error(`Received status : ${response.status}`);
        }
    );
};

export const createApiThunk = <T>(typePrefix: string, path: string, methodName?: string) => {
    methodName = methodName || 'POST';
    return createAsyncThunk(
        typePrefix,
        async (data: T) => {
            console.info("createApiThunk 1" +path);
            const requestHeaders: HeadersInit = new Headers();
            requestHeaders.set('Content-Type', 'application/json');

            // In dev mode - send the access token via the headers
            // done due to cross domain cookies issue with the dev server
            // if (isDevelopmentMode()) {

               
               // const cookies = new Cookies();
               
                


            let CSRF =layoutService.CSRF;// cookies.get('CSRF');

            if (CSRF) {
                requestHeaders.set('CSRF', CSRF);
            }
            let SessionData =layoutService.SessionData;// cookies.get('SessionData');

            if (SessionData) {
                requestHeaders.set('SessionData', JSON.stringify(SessionData));
            
            }
            

            const fetchReq: RequestInit = {
                method: methodName,
                mode: 'cors',
                cache: 'no-cache',
                headers: requestHeaders
            };
            let SessionId = layoutService.SessionId;////cookies.get('SessionId');

            if (SessionId) {
                const a:any=data;
                a.SessionId=SessionId;
               
            
            }

            if (data) {
                fetchReq.body = JSON.stringify(data);
            }

           



            const response = await fetch(`${getBaseApiUrl()}${path}`, fetchReq);

            if (response.status === 204) {
                // Empty content - return an empty object
                return {};
            }

            if (response.status === 200) {
                return response.json();
            }

            // If we receive 401 - let the appError handler handle it            
            errService.HandleReceivedError(path, response.status);

            throw Error(`Received status : ${response.status}`);
        }
    );
};

